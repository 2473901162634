import React from 'react';
import { string } from 'prop-types';

import css from './LandingPageSeasonPassSection.module.css';
import { NamedLink, PrimaryButton } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';

const LandingPageSeasonPassSection = props => {
  return (
    <>
      <section className={css.root}>
        <div>
          <div className={css.titleBox}>
            <p className={css.title}>
              <FormattedMessage id="LandingPageSeasonPassSection.title" />
            </p>
            <p className={css.subTitle}>
              <FormattedMessage id="LandingPageSeasonPassSection.subTitle" />
            </p>
            <NamedLink className={css.routerLink} name="SeasonPassPage">
              <PrimaryButton className={css.button}>
                <FormattedMessage id="LandingPageSeasonPassSection.routerLink" />
              </PrimaryButton>
            </NamedLink>
          </div>
          <div className={css.videoBox}>
            <video
              controls
              poster="/static/media/seasonPassPoster.png"
              className={`elementor-video-iframe ${css.video}`}
              src="/static/media/season-pass.mp4"
            ></video>
          </div>
        </div>
      </section>
    </>
  );
};

LandingPageSeasonPassSection.defaultProps = {
  rootClassName: null,
  className: null,
};

LandingPageSeasonPassSection.propTypes = {
  rootClassName: string,
  className: string,
};

export default LandingPageSeasonPassSection;
